<template>
    <div class="companyStore">
        <headLine msg='企业库'></headLine>  
        <div class="headTop">
            <span>按公司名称查询：</span>
            <el-input placeholder="请输入公司名称"
            clearable v-model="searchVal" class="input-with-select" style="width:300px">
            <el-button slot="append" icon="el-icon-search" @click="search()"></el-button>
            </el-input>
            <span class="second">按美和易思对接人查询：</span>
            <el-select 
            v-model="teaId" 
            @change="selectTea"
            clearable>
                <el-option
                v-for="(item,k) in teaList"
                :key="k"
                :label="item.name"
                :value="item.id"
                >
                </el-option>
            </el-select>
            <div class="right">
                <el-button size="small"  @click="companyDown('/tea/company/download')" type="warning" icon="el-icon-download">企业模板下载</el-button>
                <div class="upload">
                    <el-button size="small"  type="warning" icon="el-icon-upload">企业信息导入</el-button>
                    <input id="planCsv" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @change="uploadCompany"/>
                </div>
                <el-button size="small"  @click="companyDown('/tea/company/list/export')" type="primary" icon="el-icon-download">导出</el-button>
            </div>
            
        </div>
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
            type="index"
            label="#序号"
            width="60">
            </el-table-column>
           
            <el-table-column
            width="180"
            prop="companyName"
             :show-overflow-tooltip="true"
            label="企业名称">
            </el-table-column>
            <el-table-column
            prop="companyTradeName"
             :show-overflow-tooltip="true"
            label="行业类别">
            </el-table-column>
            <el-table-column
            prop="companyScale"
             :show-overflow-tooltip="true"
            label="企业规模">
            </el-table-column>
             <el-table-column
             :show-overflow-tooltip="true"
            label="所在区域">
                <template slot-scope="scope">
                   {{scope.row.province}}/{{scope.row.city}}
                </template>
            </el-table-column>       
            <el-table-column
            prop="contactsName"
             :show-overflow-tooltip="true"
            label="企业对接人">
            </el-table-column>
            <el-table-column
            prop="phoneNum"
             :show-overflow-tooltip="true"
            label="联系电话">
            </el-table-column>
             <el-table-column
             :show-overflow-tooltip="true"
            label="美和易思对接人">
                <template slot-scope="scope">
                   {{scope.row.linkTeacherDetail?scope.row.linkTeacherDetail.name:'-'}}
                </template>
            </el-table-column>
            <el-table-column
            prop="createdAt"
             :show-overflow-tooltip="true"
            label="创建时间">
            </el-table-column>
            <el-table-column label="企业状态">
                <template slot-scope="scope">
                     <el-switch
                        v-model="scope.row.isUsed"
                        :active-value="true"
                        :inactive-value="false"
                        active-color="#13ce66"
                        @change="changeUsed(scope.row)"
                        >
                        </el-switch>             
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button size="mini"
                    type="primary"
                    @click="lookCompany(scope.row)"
                    >企业详情</el-button> 
                    <el-button 
                     size="mini" type="danger" @click="delCom(scope.row.id)">删除</el-button>              
                </template>
            </el-table-column>
           
        </el-table>
        <div class="pages">
            <paging v-show="tableData.length>0" :totalpage="totalpage"
        @getInitList="getInitList" ref="pages" v-on:handle="handle"></paging>
        <p>共<span>{{totalCount}}</span>条</p>
        </div>
        
        
    </div>
</template>
<script>
export default {
    data () {
        return {
            searchVal:'',
            tableData:[],
            totalpage:1,
            doubleId:null,
            comId:null,
            current:0,
            teaList:[],
            teaId:null,
            exportUrl:'/tea/company/list/export',//导出的url
            totalCount:0
        }
    },
    methods: {
        uploadCompany(){
             let formData = new window.FormData()
              let comCsv = document.querySelector("input[id='planCsv']").files[0]
              formData.append('file', comCsv)
              this.$htp.post_('/tea/company/list/import',formData).then((res)=>{   
                   
                   if(res.code==0){
                       this.getInitList(0)
                       this.$message({
                           type:'success',
                           message:'导入成功'
                       })
                   }else{
                       this.$message({
                           type:'warning',
                           message:res.message||"导入失败，请重新导入"
                       })
                   }
            })
        },
        companyDown(urls){
            let base_url=this.$htp.base_url
            let url=base_url+urls
            this.$htp.export_(url).then((res)=>{
                // 创建Blob对象，设置文件类型
                const blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                const objectUrl = URL.createObjectURL(blob) // 创建URL
                window.location.href = objectUrl
                
            }) 
        },
        selectTea(){
            this.getInitList(0,this.searchVal,this.teaId)
        },
        search(){
            this.getInitList(0,this.searchVal,this.teaId)
        },
        changeUsed(row){           
            let {id,isUsed}=row
            let obj={id,isUsed}
            this.$htp.put_('/tea/company/update',obj,true).then(()=>{   
                this.getInitList(this.current)     
            })
        },
        delCom(id){
            this.$confirm(`此操作将永久删除该企业, 是否继续?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.$htp.del_('/tea/company/delete?companyId='+id,{},true).then(()=>{
                    this.getInitList(this.current)     
                })
            }).catch(() => {
                        
            });
        },
        handle(val){
            this.current=val 
        }, 
        //查看公司详情
        lookCompany(row){
            this.$router.push({name:'companyDetail',params:{row,Comestore:true}})
        },
        getInitList(current,companyName,teaId){
            let url= companyName
            ?`/tea/company/list?page=${current}&companyName=${this.searchVal}`
            :`/tea/company/list?page=${current}`  
            let teaUrl=teaId?`&teaId=${teaId}`:""
            url+=teaUrl
            this.$htp.get_(url).then((res)=>{
                this.tableData=res.data.rows
                this.totalpage=res.data.total  
                this.totalCount=res.data.size  
            }) 
        },
        getTeaList(){
           this.$htp.get_('/hr/linkTea/list').then((res)=>{
                this.teaList=res.data
            })  
        }
    },
    mounted(){
        this.getInitList(0)
        this.getTeaList()
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/common.scss';
.right{
    position:absolute;
    right: 0px;
}
.upload{
    display: inline-block;
    position: relative;
    margin: 0px 20px;
    input{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 120px;
        height: 30px;
        opacity: 0;
    }
}
.pages{
    position: relative;
    p{position: absolute;
    top: -5px;
    left: 500px;
    font-size: 14px;
    color: #606266;
        span{
            margin: 0 3px;
            color: #409EFF;
        }
    }
}
</style>
